import { useContext, useState, useEffect, useRef } from "react";
import Marquee from "../Marquee";
import "./style.scss";
import { MagasinContext } from "../../services/MagasinContext";
import getImageUrl from "../../services/getImageUrl";

/**
 * Composant Banner
 * Ce composant représente la bannière d'accueil du site web.
 * Il affiche le logo du magasin, des informations sur l'opticien, et un texte défilant en bas de la bannière.
 *
 * @param {string} info - Le texte à afficher dans la bande défilante (Marquee)
 */
const BannerAccueil = ({ info }) => {
    // Accéder aux données du style depuis le contexte
    const { dataStyle } = useContext(MagasinContext);

    // Rend les Url des images
    const logo_banniere = getImageUrl(dataStyle?.logo_banniere);

    // État pour gérer l'affichage de l'image et de la vidéo
    const [showVideo, setShowVideo] = useState(false);
    const [showImageAfterVideo, setShowImageAfterVideo] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        // Changer l'affichage après 4 secondes
        const timer = setTimeout(() => {
            setShowVideo(true);
            console.log("Affichage de la vidéo après 3 secondes");
        }, 3000);

        // Nettoyer le timer
        return () => clearTimeout(timer);
    }, []);

    const handleVideoEnd = () => {
        setShowVideo(false);
        setShowImageAfterVideo(true);
    };

    const toggleMute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !videoRef.current.muted;
            setIsMuted(videoRef.current.muted);
        }
    };

    return (
        // La section banner occupe toute la hauteur de la vue (vh-100)
        <section className="banner vh-100">
            {/* Afficher l'image ou la vidéo en fonction de l'état */}
            {showVideo ? (
                <>
                    <video
                        ref={videoRef}
                        className="banner__media"
                        src="/images/banner.mp4"
                        autoPlay
                        muted={isMuted}
                        playsInline
                        onEnded={handleVideoEnd}
                    ></video>
                    <button
                        onClick={toggleMute}
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "20px",
                            padding: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        {isMuted ? "Activer le son" : "Désactiver le son"}
                    </button>
                </>
            ) : (
                <>
                    <div className="banner__wrapper">
                        <img
                            className="banner__wrapper--logo"
                            src={logo_banniere}
                            alt="Logo Tavernier"
                        />
                        <div className="banner__wrapper--text">
                            <p>Opticien lunetier</p>
                            <p>Depuis 1966</p>
                        </div>
                    </div>
                    <img
                        className="banner__media"
                        src="/images/banner.webp"
                        alt="Banner"
                        style={{ display: showImageAfterVideo ? "block" : "none" }}
                    />
                    {/* Marquee affichant le texte défilant avec le contenu passé en prop */}
                    <div className="banner__marquee">
                        <Marquee info={info} />
                    </div>
                </>
            )}
        </section>
    );
};

export default BannerAccueil;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Accueil from "./pages/Accueil";
import Services from "./pages/Services";
import Marques from "./pages/Marques";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/Footer";
import Mentions from "./pages/Mentions";
import Meta from "./services/Meta";
import Equipe from "./pages/Equipe";
import ScrollToTop from "./services/ScrollToTop";

export default function Router() {
    return (
        <BrowserRouter>
            <Meta />
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<Accueil />} />
                <Route path="/equipe" element={<Equipe />} />
                <Route path="/services" element={<Services />} />
                <Route path="/marques" element={<Marques />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/mentions" element={<Mentions />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

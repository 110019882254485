import React, { useState, useEffect, useContext } from "react";
import { MagasinContext } from "../../services/MagasinContext";
import fetchInstagramPosts from "../../services/instagramService";
import getImageUrl from "../../services/getImageUrl";
import Marquee from "../../components/Marquee";
import CardService from "../../components/CardServiceLink";
import CardGoogle from "../../components/CardGoogle";
import BannerAccueil from "../../components/BannerAccueil";
import "./style.scss";

/**
 * Composant `Accueil` : Page d'accueil d'Optique Tavernier
 *
 * Ce composant gère l'affichage de la page d'accueil en récupérant et affichant
 * des informations dynamiques telles que les posts Instagram, les services proposés
 * par le magasin, les avis des clients, et d'autres informations pertinentes.
 */
const Accueil = () => {
    // Accéder aux données de la page d'accueil depuis le contexte
    const { pageAccueil } = useContext(MagasinContext);
    // Declaration des etats pour les posts Instagram
    const [posts, setPosts] = useState([]);

    // Effect pour récupérer les posts Instagram
    useEffect(() => {
        const getPosts = async () => {
            const postsList = await fetchInstagramPosts();
            setPosts(postsList);
        };

        getPosts();
    }, []);

    // Ne rien afficher si les données ne sont pas encore disponibles
    if (!pageAccueil || !posts) return null;

    return (
        <main>
            <section className="accueil__banner">
                <BannerAccueil info={<h1>Bienvenue chez Optique Tavernier・</h1>} />
            </section>
            <section className="accueil__marquee">
                <Marquee
                    info={<img src="/images/liste_marques.webp" alt="bannière des marques" />}
                />
            </section>
            <div className="main__container">
                <section>
                    <h2 className="titleUnderline"> Optique Tavernier votre opticien à Epinal</h2>
                    <div className="row g-3">
                        {pageAccueil.service_accueil.map((service, index) => (
                            <div
                                className={`col-12 col-md-${index < 4 ? "6" : "12"} col-lg-${
                                    index < 3 ? "4" : "6"
                                }`}
                                key={index}
                            >
                                <CardService
                                    image={getImageUrl(service.image)}
                                    title={service.titre}
                                />
                            </div>
                        ))}
                    </div>
                </section>
                <section>
                    <h2 className="titleUnderline">Les avis de nos clients</h2>
                    <div className="container">
                        <div className="row justify-content-center">
                            {pageAccueil.avis_google.map((avis, index) => (
                                <div className="col-12" key={index}>
                                    <CardGoogle
                                        image={getImageUrl(avis.image_profil)}
                                        title={avis.nom}
                                        stars={avis.etoiles}
                                        date={avis.date}
                                        content={avis.commentaire}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section>
                    <h2 className="titleUnderline">Instagram : Suivez-nous !</h2>
                    <div className="container">
                        <div className="row g-2 justify-content-center">
                            {posts.map((post) => (
                                <div
                                    className="col-12 col-md-4 d-flex justify-content-center"
                                    key={post.id}
                                >
                                    <div className="insta__card">
                                        {/* Vérifier si l'URL contient '.mp4' */}
                                        {post.media_url.includes(".mp4") ? (
                                            <video
                                                className="card-img-top insta-media"
                                                autoPlay
                                                muted
                                            >
                                                <source src={post.media_url} type="video/mp4" />
                                                Votre navigateur ne prend pas en charge la vidéo.
                                            </video>
                                        ) : (
                                            <img
                                                src={post.media_url}
                                                className="card-img-top"
                                                alt="Instagram post"
                                            />
                                        )}
                                        <div className="card-body text-center">
                                            <a
                                                href={post.permalink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-primary"
                                            >
                                                Voir sur Instagram
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className="mx-5">{pageAccueil.text_insta}</p>
                </section>
            </div>
        </main>
    );
};

export default Accueil;
